import Container from 'react-bootstrap/Container';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Games from "./games/Games";
import Members from "./members/Members";
import Ranking from "./ranking/Ranking";
import Matchdays from "./season/Matchdays";

const App = () => {
    return (
        <Container>
            <h1>Veteranen Oedelem</h1>
            <Tabs
                defaultActiveKey="games"
                id="app-tab"
                className="mb-3"
            >
                <Tab eventKey="games" title="Wedstrijden">
                    <Games/>
                </Tab>
                <Tab eventKey="members" title="Leden">
                    <Members/>
                </Tab>
                <Tab eventKey="matchdays" title="Speeldagen">
                    <Matchdays/>
                </Tab>
                {/*<Tab eventKey="ranking" title="Rangschikking">
                    <Ranking/>
                </Tab>*/}
            </Tabs>
        </Container>
    );
}

export default App;