import {Button, Stack, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import fetchMatchdays from "./api/matchdays";
import {formatDate, formatScore} from "../games/js/games";
import {getLastPlayedMatchday, getNextPlayedMatchday, getPreviousPlayedMatchday} from "./js/matchdays";

export const Ranking = (props) => {

    const rankingRecords = props.ranking;

    return (
        <div>
            <Table striped size="sm">
                <thead>
                <tr>
                    <th></th>
                    <th>Team</th>
                    <th>Ptn</th>
                    <th>M</th>
                    <th>M+</th>
                    <th>M-</th>
                    <th>M=</th>
                    <th>D+</th>
                    <th>D-</th>
                </tr>
                </thead>
                <tbody data-cy="rankingTable">
                {rankingRecords.map((teamRanking, index) =>
                    <tr>
                        <td>{index + 1}</td>
                        <td>{teamRanking.team}</td>
                        <td>{teamRanking.points}</td>
                        <td>{teamRanking.win + teamRanking.loss + teamRanking.draw}</td>
                        <td>{teamRanking.win}</td>
                        <td>{teamRanking.loss}</td>
                        <td>{teamRanking.draw}</td>
                        <td>{teamRanking.scored}</td>
                        <td>{teamRanking.conceded}</td>
                    </tr>
                )}
                </tbody>
            </Table>
        </div>)

}

const Matchdays = () => {

    const [matchdays, setMatchdays] = useState([]);
    const [matchday, setMatchday] = useState({});
    const [games, setGames] = useState([]);
    const [ranking, setRanking] = useState([]);

    useEffect(() => {
        fetchMatchdays().then(matchdays => {
            setMatchdays(matchdays);
            const lastPlayedMatchday = getLastPlayedMatchday(matchdays);
            updateMatchdayData(lastPlayedMatchday);
        });
    }, []);

    const scrollNextMatchday = () => {
        const nextPlayedMatchday = getNextPlayedMatchday(matchdays, matchday);
        updateMatchdayData(nextPlayedMatchday);
    }

    const scrollPreviousMatchday = () => {
        const previousPlayedMatchday = getPreviousPlayedMatchday(matchdays, matchday);
        updateMatchdayData(previousPlayedMatchday);
    }

    const updateMatchdayData = (matchday) => {
        setMatchday(matchday);
        setGames(matchday.games);
        setRanking(matchday.ranking);
    }

    return (
        <div>
            <h2>Speeldagen</h2>
            <Stack direction="horizontal" gap={3}>
                <div className="p-2"><Button data-cy="btnPreviousMatchday" variant="outline-primary"
                                             onClick={scrollPreviousMatchday}>{'<<'}</Button></div>
                <div className="p-2"><em data-cy="dateOfMatchday">{formatDate(matchday.date)}</em></div>
                <div className="p-2"><Button data-cy="btnNextMatchday" variant="outline-primary"
                                             onClick={scrollNextMatchday}>{'>>'}</Button></div>
            </Stack>
            <Table striped size="sm">
                <thead>
                </thead>
                <tbody data-cy="listOfGames">
                {games.map((game, index) =>
                    <tr key={index}>
                        <td>{game.homeTeam} - {game.guestTeam}</td>
                        <td>{formatScore(game)}</td>
                    </tr>
                )}
                </tbody>
            </Table>
            {ranking && <Ranking ranking={ranking} />}
        </div>
    )
}

export default Matchdays