import * as R from "ramda";

export const fetchListOfTeams = R.pipe(
    R.map(R.props(['homeTeam', 'guestTeam'])), // Extract home and guest teams
    R.flatten, // Flatten into a single array
    R.uniq, // Get unique teams
    R.sortBy(R.complement(R.equals('VETERANEN OEDELEM'))) // Sort with "Veteranen Oedelem" first
);

export const filterMatchesByTeam = (teamName) => R.filter(
    R.either(
        R.propEq(teamName,'homeTeam'),
        R.propEq(teamName, 'guestTeam')
    )
);

export const fetchNext = (games) => {
    return games.find(g => new Date(g.date) > Date.now());
}

export const formatDate = dateString => {
    if (!dateString) {
        return "";
    }
    let dateObject = new Date(dateString);

    const day = String(dateObject.getDate()).padStart(2, '0');
    const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    const year = dateObject.getFullYear();
    return `${day}/${month}/${year}`;
}

export const formatScore = game => {
    if (game.homeGoals < 0) {
        return "N/A"
    }
    return game.homeGoals + "-" + game.guestGoals;
}