import * as R from "ramda";

export const getLastPlayedMatchday = matchdays => {
    const today = new Date();
    return R.findLast(
        R.propSatisfies(
            date => new Date(date) < today,
            'date'
        ), matchdays);
}

export const getNextPlayedMatchday = (matchdays, afterMatchday) => {
    const currentIndex = R.findIndex(R.propEq(afterMatchday.date, 'date'))(matchdays);
    const nextIndex = currentIndex + 1;
    if (matchdays[nextIndex]) {
        const nextMatchday = matchdays[nextIndex];
        if (new Date(nextMatchday.date) < new Date()) {
            return nextMatchday;
        } else {
            return afterMatchday;
        }
    } else {
        return afterMatchday;
    }
}

export const getPreviousPlayedMatchday = (matchdays, beforeMatchday) => {
    const currentIndex = R.findIndex(R.propEq(beforeMatchday.date, 'date'))(matchdays);
    const nextIndex = currentIndex - 1;
    if (nextIndex >= 0) {
        return matchdays[nextIndex];
    } else {
        return beforeMatchday;
    }
}
