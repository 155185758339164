import React, {useEffect, useState} from "react";
import getMembers from "./api/members"
import MembersPayed from "./MembersPayed";
import {countNrOfPayedMembers, sortByGoals, sortByName, sortByPresence} from "./js/members";

import {ArrowDownSquareFill, ArrowUpSquareFill, ClockFill, RocketTakeoffFill} from "react-bootstrap-icons";
import styled from "styled-components";

const TableHeader = styled.th`
    text-align: center;
`

const TableCell = styled.td`
    padding-left: .5em;
    padding-right: .5em;
    text-align: ${props => (props.align)};
`

const MembersColumn = (props) => {
    return (
        <TableHeader onClick={props.onClick}>{props.title}</TableHeader>
    );
}

const MembersRow = (props) => {
    const bgColor = props.payed ? '' : 'red';

    const defaultToNotAvailable = (value) => {

        if (value === undefined) {
            return "N/A";
        }
        return value;
    }

    const games = props.games;
    const started = defaultToNotAvailable(props.started);
    const subOut = defaultToNotAvailable(props.subOut);
    const subIn = defaultToNotAvailable(props.subIn);
    const minutes = defaultToNotAvailable(props.minutes);

    return (
        <tr key={props.index}>
            <TableCell style={{
                backgroundColor: bgColor
            }}>{props.idName}</TableCell>
            <TableCell align={'center'}>{props.goals}</TableCell>
            <MemberGamesCell games={games} started={started} subOut={subOut} subIn={subIn} minutes={minutes}></MemberGamesCell>
            <TableCell align={'center'}>{defaultToNotAvailable(props.points)}</TableCell>
        </tr>
    );
}

const MemberGamesCell = (props) => {
    return (
        <TableCell align={'center'}>
            <span>{props.games}</span>&nbsp;
            <span>(<RocketTakeoffFill size={12} color={'blue'}></RocketTakeoffFill> {props.started},</span>&nbsp;
            <span><ArrowUpSquareFill size={12} color={'red'}></ArrowUpSquareFill> {props.subOut},</span>&nbsp;
            <span><ArrowDownSquareFill size={12} color={'green'}></ArrowDownSquareFill> {props.subIn},</span>&nbsp;
            <span><ClockFill size={12} color={'blue'}></ClockFill> {props.minutes})</span>
        </TableCell>
)
}

const Members = () => {

    const [members, setMembers] = useState([]);
    const [amountMembersPayed, setAmountMembersPayed] = useState(0);
    const [amountMembers, setAmountMembers] = useState(0);

    const sortMembersByName = () => {
        setMembers(sortByName(members));
    }

    const sortMembersByGoals = () => {
        setMembers(sortByGoals(members));
    }

    const sortMembersByPresence = () => {
        setMembers(sortByPresence(members));
    }

    useEffect(() => {
        getMembers().then(data => {
            setMembers(sortByName(data));
            setAmountMembersPayed(countNrOfPayedMembers(data));
            setAmountMembers(data.length);
        });
    }, []);

    return (
        <div>
            <h1 data-cy="nrOfMembers">Leden: {members.length}</h1>
            <MembersPayed payed={amountMembersPayed} total={amountMembers}/>
            <table data-cy="tableOfMembers">
                <thead>
                <tr>
                    <MembersColumn title={"Lid"} onClick={sortMembersByName}/>
                    <MembersColumn title={"Goals"} onClick={sortMembersByGoals}/>
                    <MembersColumn title={"Wedstrijden"} onClick={sortMembersByPresence} />
                    <MembersColumn title={"Punten"} />
                </tr>
                </thead>
                <tbody data-cy="listOfMembers">
                {members.map((player, index) =>
                    <MembersRow key={index} index={index}
                                idName={player.idName}
                                payed={player.payed}
                                goals={player.gameStatistics.goals}
                                games={player.gameStatistics.present}
                                started={player.gameStatistics.started}
                                subOut={player.gameStatistics.subOut}
                                subIn={player.gameStatistics.subIn}
                                minutes={player.gameStatistics.minutes}
                                points={player.gameStatistics.points}/>
                )}
                </tbody>
            </table>
        </div>
    );
}

export default Members