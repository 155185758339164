import React, {useEffect, useState} from "react";
import getGamesSortedByDate from "./api/games";
import {Card, CardBody, CardText, CardTitle, Table} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {fetchListOfTeams, fetchNext, filterMatchesByTeam, formatDate, formatScore} from "./js/games";

const Games = () => {

    const [nextGame, setNextGame] = useState({});
    const [teams, setTeams] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState('VETERANEN OEDELEM');
    const [shownGames, setShownGames] = useState([]);

    const handleChangeFilter = event => {
        console.log("handleChangeFilter", event);
        setSelectedTeam(event.target.value);
    }

    useEffect(() => {
        getGamesSortedByDate().then(data => {
            setNextGame(fetchNext(data));
            setTeams(fetchListOfTeams(data));
            const filteredGames = filterMatchesByTeam(selectedTeam)(data);
            setShownGames(filteredGames);
        });
    }, [selectedTeam]);

    return (

        <div>
            <div>
                <h2>Volgende wedstrijd</h2>
                <Card>
                    <CardBody>
                        <CardTitle>{nextGame?.homeTeam} - {nextGame?.guestTeam}</CardTitle>
                        <CardText>{formatDate(nextGame?.date)}</CardText>
                    </CardBody>
                </Card>
            </div>
            <div>
                <h2>Wedstrijden</h2>
                <Form.Select data-cy="teamSelector" name="teamSelector" value={selectedTeam} onChange={handleChangeFilter}>
                    {teams.map((team, index) =>
                    <option key={index} value={team}>{team}</option>
                    )}
                </Form.Select>
                <Table striped size="sm">
                    <thead>
                    <tr>
                        <th>Datum</th>
                        <th>Wedstrijd</th>
                        <th>Uitslag</th>
                    </tr>
                    </thead>
                    <tbody data-cy="listOfGames">
                    {shownGames.map((game, index) =>
                        <tr key={index}>
                            <td>{formatDate(game.date)}</td>
                            <td>{game.homeTeam} - {game.guestTeam}</td>
                            <td>{formatScore(game)}</td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

export default Games;