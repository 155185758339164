import * as R from "ramda";

export const countNrOfPayedMembers = (members) => {
    return R.pipe(
        R.filter(R.propEq(true, 'payed')),
        R.length
    )(members);
}

export const sortByName = R.sortWith([
    R.ascend(R.prop('idName')),
]);

export const sortByGoals = R.sortWith([
    R.descend(R.path(['gameStatistics', 'goals'])),
    R.ascend(R.prop('name')),
]);

export const sortByPresence = R.sortWith([
    R.descend(R.path(['gameStatistics', 'present'])),
    R.ascend(R.prop('name')),
]);